/* * @Author: liuzhixiang * @Date: 2021-02-06 14:42:43 * @Last Modified by:
liuzhixiang * @Last Modified time: 2021-02-06 14:42:43 */
<template>
  <div class="home">
    <div class="">
      <div class="banner width_1200">
        <el-carousel class="" trigger="click" height="300px">
          <el-carousel-item v-for="item in banners" :key="item.Id">
            <img
              v-lazy="item.ImgUrl"
              :alt="item.Name"
              height="300"
              width="100%"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 优惠课程 -->
      <div class="width_1260 yhkc" v-if="yhCourse.length > 0">
        <div class="home_title">优惠课程</div>
        <div class="home_row">
          <div
            class="hover_shadow home_col"
            v-for="(item, index) in yhCourse"
            :key="index"
            @click="goTo(item)"
          >
            <div class="img_dom">
              <img v-lazy="item.CoverImg" alt="" srcset="" />
              <div
                v-if="item.CoursePackage == 0"
                :class="
                  item.CourseType === 1
                    ? 'dbk'
                    : item.CourseType === 0
                    ? 'zbk'
                    : 'msk'
                "
              >
                {{
                  item.CourseType === 1
                    ? "点播课"
                    : item.CourseType === 0
                    ? "直播课"
                    : "面授课"
                }}
              </div>
              <div v-else class="kcb">课程包</div>
            </div>
            <!-- <img v-lazy="item.CoverImg" alt="" srcset="" /> -->
            <div class="rig">
              <div class="title decimalPoint">{{ item.Name }}</div>
              <div class="time">
                {{ item.ValidityDateStart | dataYMD }}-{{
                  item.ValidityDateEnd | dataYMD
                }}
              </div>
              <div class="per">
                <div v-for="(it, i) in item.TeacherInfo.slice(0, 3)" :key="i">
                  <img v-lazy="it.HeadImg" alt="" srcset="" />
                  <span>{{ it.Name }}</span>
                </div>
              </div>
              <div class="price">
                <div><span>￥</span>{{ item.OriginalPrice }}</div>
                <div v-if="item.ShowPrice">￥{{ item.ShowPrice }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="home_btn" @click="geToCourse(1)">
          查看更多&nbsp;<i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <!-- 推荐课程 -->
      <div class="width_1260 tjkc" v-if="tjCourse.length > 0">
        <div class="home_title">推荐课程</div>
        <div class="home_row">
          <div
            class="hover_shadow home_col"
            v-for="(item, index) in tjCourse"
            :key="index"
            @click="goTo(item)"
          >
            <!-- <img v-lazy="item.CoverImg" alt="" srcset="" />
            <div :class="item.CourseType === 1 ? 'dbk' : 'zbk'">
              {{ item.CourseType === 1 ? "点播课" : "直播课" }}
            </div> -->
            <div class="img_dom">
              <img v-lazy="item.CoverImg" alt="" srcset="" />
              <div
                v-if="item.CoursePackage == 0"
                :class="
                  item.CourseType === 1
                    ? 'dbk'
                    : item.CourseType === 0
                    ? 'zbk'
                    : 'msk'
                "
              >
                {{
                  item.CourseType === 1
                    ? "点播课"
                    : item.CourseType === 0
                    ? "直播课"
                    : "面授课"
                }}
              </div>
              <div v-else class="kcb">课程包</div>
            </div>
            <div class="title decimalPoint">{{ item.Name }}</div>
            <div class="perPrice">
              <div
                class="per"
                v-for="(it, i) in item.TeacherInfo.slice(0, 2)"
                :key="i"
              >
                <div>
                  <img v-lazy="it.HeadImg" alt="" srcset="" />
                  <span>{{ it.Name }}</span>
                </div>
              </div>
              <div
                class="price"
                v-if="item.IsCharge === true && item.OriginalPrice != null"
              >
                ￥{{ item.OriginalPrice }}
              </div>
              <div v-else>免费</div>
            </div>
          </div>
        </div>
        <div class="home_btn" @click="geToCourse(2)">
          查看更多&nbsp;<i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <!-- 艺术展推荐 -->
      <div class="width_1260 tjkc ysztj" v-if="artList.length > 0">
        <div class="home_title">艺术展推荐</div>
        <div class="home_row">
          <div
            class="hover_shadow home_col"
            v-for="(item, index) in artList"
            :key="index"
            @click="goToArtDetail(item)"
          >
            <img v-lazy="item.ArtImg" alt="" srcset="" />
            <div class="title decimalPoint">{{ item.ArtTitle }}</div>
            <div class="perPrice">
              <div>{{ item.ArtistName }}</div>
              <div>{{ item.CreateTime | dataYMD }}</div>
            </div>
          </div>
        </div>
        <div class="home_btn" @click="geToArt">
          查看更多&nbsp;<i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <!-- 商品推荐 -->
      <div class="width_1260 tjkc sptj" v-if="goodsList.length > 0">
        <div class="home_title">商品推荐</div>
        <div class="home_row">
          <div
            class="hover_shadow home_col"
            v-for="(item, index) in goodsList"
            :key="index"
            @click="goTogoodsDetail(item.Id)"
          >
            <img v-lazy="item.CoverImg" alt="" srcset="" />
            <div class="title decimalPoint">{{ item.GoodsName }}</div>
            <div class="perPrice">
              <div class="price"><span>￥</span>{{ item.DefaultPrice }}</div>
            </div>
          </div>
        </div>
        <div class="home_btn" @click="goTogoods">
          查看更多&nbsp;<i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <!-- 资深讲师 -->
      <div class="width_1260 zsjs" v-if="teacherList.length > 0">
        <div class="home_title">资深讲师</div>
        <div class="home_row">
          <div
            class="hover_shadow home_col"
            v-for="(item, index) in teacherList"
            :key="index"
            @click="goToTeacherDetail(item.Id)"
          >
            <img v-lazy="item.HeadImg" alt="" srcset="" />
            <div class="title decimalPoint">{{ item.Name }}</div>
            <div class="intr">{{ item.ShortTitle }}</div>
          </div>
        </div>
        <div class="home_btn" @click="goToTeacher">
          查看更多&nbsp;<i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <!-- 艺术资讯 -->
      <div class="width_1260 yszx" v-if="newsList.length > 0">
        <div class="home_title">艺术资讯</div>
        <div class="home_row">
          <div
            class="hover_shadow home_col"
            v-for="(item, index) in newsList"
            :key="index"
            @click="handleTo(item)"
          >
            <img v-lazy="item.CoverImg" alt="" srcset="" />
            <div class="rig">
              <div class="title decimalPoint">
                {{ item.Title }}
              </div>
              <div class="time">
                <div>{{ item.CreateTime | dataYMD }}</div>
                <div v-if="item.ReadCount && item.ReadCount != 0">
                  {{ item.ReadCount }}人看过
                </div>
              </div>
            </div>
          </div>
        </div>
        <router-link class="home_btn" to="/informationList/index">
          <div>查看更多&nbsp;<i class="el-icon-arrow-right"></i></div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {
  courseQueryCourseList,
  baseQueryTeacherList,
  commonQueryBannerList,
  commonQueryNewsList, updateNewsReadCount
} from "@/api/home";
import {
  queryArtExhibitionList,
  queryArtExhibitionListNew
} from "@/api/art";
import {
  queryShopGoodsListByApp
} from "@/api/shop";
export default {
  data () {
    return {
      banners: [],
      yhCourse: [],
      tjCourse: [],
      artList: [],
      goodsList: [],
      teacherList: [],
      newsList: [],
    };
  },
  components: {
  },
  created () { },
  mounted () {
    this.getCommonQueryBannerList();
    this.postCourseQueryCourseList(1);// 优惠课程包
    this.postCourseQueryCourseList(2);// 推荐课程
    this.postQueryArtExhibitionList();// 艺术展列表
    this.postQueryShopGoodsListByApp();// 商品列表
    this.postBaseQueryTeacherList();// 资深讲师
    this.postCommonQueryNewsList();// 艺术资讯
  },
  methods: {
    handleTo (obj) {
      if (obj.JumpUrl) {
        this.postUpdateNewsReadCount(obj.Id)
        window.open(obj.JumpUrl)
      } else {
        let path = "/informationList/details";
        let query = {
          id: obj.Id,
        };
        this.$router.push({ path, query });
      }
    },
    //浏览量自动+1
    async postUpdateNewsReadCount (id) {
      let parm = "?Id=" + id
      const res = await updateNewsReadCount(parm);
    },
    goTo (obj, type) {
      let ob = {
        Id: obj.Id,
        Name: obj.Name,
        CoursePackage: obj.CoursePackage,
      };
      this.$router.push({ path: "/course/details", query: ob });
    },
    goToTeacherDetail (id) {
      this.$router.push({ path: '/teacher/teacherDetails', query: { id: id, type: 1 } });
    },
    goToTeacher () {
      this.$router.push({
        path: `/teacher/index`,
        query: { type: 1 }
      })
    },
    goTogoodsDetail (id) {
      this.$router.push({ name: 'PCshop/goods/goodsdetail', query: { id: id } });
    },
    goTogoods () {
      this.$router.push({
        path: `/PCshop/PChome`
      })
    },
    geToCourse (i) {
      this.$router.push({
        name: `course/index`,
        params: {
          index: i
        }
      })
    },
    geToArt () {
      this.$router.push({
        path: `/art`
      })
    },
    goToArtDetail (obj) {
      localStorage.setItem("ImgSrc", obj.ImgSrc);
      let routeUrl = this.$router.resolve({
        path: "/PCshop/goods/goodsdetail?id="+obj.ArtId,
        query: {},
      });
      window.open(routeUrl.href, "_blank");
    },
    // 艺术资讯
    async postCommonQueryNewsList () {
      let parm = {}
      parm.pageIndex = 1
      parm.pageSize = 4
      const res = await commonQueryNewsList(parm);
      if (res.success == true) {
        this.newsList = res.response.data.slice(0, 4)
      } else {
        this.$message.error(res.msg);
      }
    },
    // 资深讲师
    async postBaseQueryTeacherList () {
      let parm = {}
      parm.pageIndex = 1
      parm.pageSize = 5
      const res = await baseQueryTeacherList(parm);
      if (res.success == true) {
        this.teacherList = res.response.data.slice(0, 5)
      } else {
        this.$message.error(res.msg);
      }
    },
    // 商品列表
    async postQueryShopGoodsListByApp () {
      let parm = {}
      parm.pageIndex = 1
      parm.pageSize = 4
      parm.isRecommend = 1 // 是否推荐（1是0否）
      const res = await queryShopGoodsListByApp(parm);
      if (res.success == true) {
        this.goodsList = res.response.data.slice(0, 4)
      } else {
        this.$message.error(res.msg);
      }
    },
    // 艺术展列表
    async postQueryArtExhibitionList () {
      let parm = {}
      parm.pageIndex = 1
      parm.pageSize = 3
      const res = await queryArtExhibitionListNew(parm);
      if (res.success == true) {
        this.artList = res.response.data.slice(0, 3)
      } else {
        this.$message.error(res.msg);
      }
    },
    // 课程
    async postCourseQueryCourseList (num) {
      let parm = {}
      parm.pageIndex = 1
      parm.pageSize = 10
      if (num == 1) {
        parm.CoursePackage = 1  // 是否课程包（0否1是）
      } else {
        parm.courseTags = '推荐'  // 课程属性（首页推荐课程传“推荐”）
      }
      const res = await courseQueryCourseList(parm);
      if (res.success == true) {
        // this.banners = res.response;
        if (num == 1) {
          this.yhCourse = res.response.data.slice(0, 4)
        } else {
          this.tjCourse = res.response.data.slice(0, 8)
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    // 轮播图
    async getCommonQueryBannerList () {
      const res = await commonQueryBannerList();
      if (res.success == true) {
        this.banners = res.response;
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.home {
  background-image: url("../../assets/images/home_bg1.png"),
    url("../../assets/images/home_bg2.png"),
    url("../../assets/images/home_bg3.png"),
    url("../../assets/images/home_bg4.png"),
    url("../../assets/images/home_bg5.png"),
    url("../../assets/images/home_bg6.png");
  background-position: center -71px, center 1127px, center 2070px, center 2828px,
    center 3623px, center 4387px;
  background-repeat: no-repeat;
  background-size: auto;
  padding: 30px 0 94px;

  .banner {
    text-align: center;
    // img {
    //   width: 100%;
    //  height: 500px;
    // }
    /deep/.el-carousel {
      margin: 0 auto 100px;
      /deep/img {
        height: 300px;
        width: 100%;
      }
    }
  }
  .home_title {
    position: absolute;
    left: 0;
    right: 0;
    top: -42px;
    margin: 0 auto;
    width: 320px;
    height: 84px;
    line-height: 84px;
    font-size: 40px;
    color: #ffffff;
    font-weight: 800;
    letter-spacing: 5px;
    text-align: center;
    // border: 5px solid #000;
    // background-color: #a980fe;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url("../../assets/images/icon_title.png");
  }
  .yhkc {
    // height: 720px;
    background-color: #ffffff;
    box-shadow: 4px 4px 12px rgba(139, 139, 139, 0.16);
    margin-bottom: 122px;
    position: relative;
    padding-bottom: 50px;
    .home_row {
      padding: 94px 0 24px;
      .home_col {
        flex: 0 0 580px;
        width: 585px;
        height: 220px;
        display: flex;
        align-items: center;
        margin-left: 30px;
        margin-bottom: 30px;
        .img_dom {
          flex: 0 0 250px;
          width: 250px;
          height: 160px;
          flex: 0 0 250px;
          margin: 0 20px 0 25px;
          position: relative;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    // img {
    //   flex: 0 0 250px;
    //   width: 250px;
    //   height: 160px;
    //   flex: 0 0 250px;
    //   margin: 0 20px 0 25px;
    // }
    .rig {
      .title {
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        line-height: 1;
        margin-bottom: 14px;
        width: 280px;
      }
      .time {
        color: #999999;
        line-height: 20px;
      }
      .per {
        display: flex;
        margin: 20px 0;
        img {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          margin: 0;
        }
        span {
          margin: 0 16px 0 6px;
        }
      }
      .price {
        display: flex;
        align-items: center;
        font-size: 22px;
        font-weight: bold;
        color: #fe4a4a;
        span {
          font-size: 0.625rem;
          font-weight: normal;
        }
        div {
          &:nth-child(2) {
            margin-left: 8px;
            font-size: 12px;
            color: #b5b4b4;
            text-decoration: line-through;
          }
        }
      }
    }
  }
  .tjkc {
    // height: 838px;
    background-color: #ffffff;
    box-shadow: 4px 4px 12px rgba(139, 139, 139, 0.16);
    margin-bottom: 120px;
    position: relative;
    padding-bottom: 50px;
    .home_row {
      padding: 94px 0 24px;
      .home_col {
        flex: 0 0 280px;
        width: 280px;
        height: 280px;
        margin-left: 26px;
        margin-bottom: 26px;
        position: relative;
        .img_dom {
          flex: 0 0 280px;
          width: 280px;
          height: 160px;
          position: relative;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    img {
      flex: 0 0 280px;
      width: 280px;
      height: 160px;
    }
    .title {
      padding: 0 20px;
      font-size: 20px;
      font-weight: bold;
      color: #333333;
      line-height: 1;
      margin-bottom: 14px;
      margin: 18px 0;
    }
    .perPrice {
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 20px 0;
      color: #666666;
      .per {
        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin: 0;
        }
        span {
          font-size: 14px;
          margin: 0 5px;
        }
      }
      .price {
        font-size: 22px;
        font-weight: bold;
        color: #fe4a4a;
        span {
          font-size: 0.625rem;
          font-weight: normal;
        }
      }
    }
  }
  .ysztj {
    // height: 590px;
    background-color: #ffffff;
    box-shadow: 4px 4px 12px rgba(139, 139, 139, 0.16);
    margin-bottom: 122px;
    position: relative;
    padding-bottom: 50px;
    .home_row {
      .home_col {
        flex: 0 0 380px;
        width: 380px;
        height: 340px;
        margin-left: 30px;
        margin-bottom: 30px;
      }
    }
    img {
      flex: 0 0 100%;
      width: 100%;
      height: 234px;
    }
    .title {
      padding: 0 20px;
      font-size: 20px;
      font-weight: bold;
      color: #333333;
      line-height: 1;
      margin-bottom: 14px;
      margin: 18px 0;
    }
  }
  .sptj {
    height: 638px;
    background-color: #ffffff;
    box-shadow: 4px 4px 12px rgba(139, 139, 139, 0.16);
    margin-bottom: 122px;
    position: relative;
    padding-bottom: 50px;
    .home_row {
      .home_col {
        height: 390px;
      }
    }
    img {
      flex: 0 0 100%;
      width: 100%;

      height: 280px;
    }
  }
  .zsjs {
    // height: 602px;
    background-color: #ffffff;
    box-shadow: 4px 4px 12px rgba(139, 139, 139, 0.16);
    margin-bottom: 122px;
    position: relative;
    padding-bottom: 50px;
    .home_row {
      padding: 94px 0 24px;
      .home_col {
        flex: 0 0 218px;
        width: 218px;
        height: 354px;
        margin-left: 28px;
        margin-bottom: 28px;
      }
    }
    img {
      flex: 0 0 218px;
      width: 218px;
      height: 218px;
    }
    .title {
      padding: 0 20px;
      font-size: 24px;
      font-weight: bold;
      color: #333333;
      line-height: 1;
      margin-bottom: 14px;
      margin: 18px 0;
      text-align: center;
    }
    .intr {
      padding: 0 20px;
      line-height: 24px;
      text-align: center;
    }
  }
  .yszx {
    // height: 638px;
    background-color: #ffffff;
    box-shadow: 4px 4px 12px rgba(139, 139, 139, 0.16);
    position: relative;
    padding-bottom: 50px;
    .home_row {
      padding: 94px 0 24px;
      .home_col {
        flex: 0 0 580px;
        width: 585px;
        height: 180px;
        display: flex;
        align-items: center;
        margin-left: 30px;
        margin-bottom: 30px;
      }
    }
    img {
      flex: 0 0 208px;
      width: 208px;
      height: 120px;
      margin: 0 24px 0 30px;
    }
    .rig {
      width: 300px;
      margin-right: 30px;
      .title {
        min-height: 60px;
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
        margin-bottom: 14px;
      }
      .time {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0;
        color: #999999;
      }
    }
  }
  .home_row {
    display: flex;
    flex-wrap: wrap;
    .home_col {
      border: 1px solid #e7e7e7;
    }
  }
  .home_btn {
    display: block;
    width: 240px;
    height: 56px;
    line-height: 56px;
    text-align: center;
    color: #ffffff;
    font-size: 14px;
    margin: 0 auto;
    cursor: pointer;
    background-color: #4887f9;
  }
}
</style>
